<template>
  <img
    v-if="src"
    :src="src"
    alt="user image"
    :style="avatarStyle"
  />
  <div
    v-else
    class="profile-solid-icon"
  >
    <ProfileSolidIcon
      width="24"
      height="24"
      fill="#68899B"
    />
  </div>
</template>

<script setup lang="ts">
  import ProfileSolidIcon from '~/assets/icons/UserMenu/profile-solid.svg'

  interface IAvatarProps {
    src: string
    size: number
  }

  const props = defineProps<IAvatarProps>()

  const avatarStyle = computed(() => ({
    width: `${props.size}px`,
    borderRadius: '50%',
  }))
</script>

<style scoped lang="scss">
  .profile-solid-icon {
    display: flex;
    padding: 8px;
  }
</style>
