<template>
  <div
    class="user-contact-container"
    :class="!isInline && 'user-contact--not-inline'"
  >
    <div class="user-avatar-container">
      <Avatar
        :size="46"
        :src="data.logo || data.user?.photo || ''"
      />
      <div>
        <Text
          type="p2"
          weight="medium"
        >
          {{ data.name || data.user?.name || '' }}
        </Text>
        <!-- @TODO: show when BE have the data -->
        <Text
          v-if="false"
          type="p3"
          class="user-total-property"
        >
          12 Properti
        </Text>
      </div>
    </div>
    <div
      v-if="data.user?.phone_number"
      class="contact-btn-container"
    >
      <Button
        type="secondary"
        variant="blue"
        :with-border="true"
        style="cursor: unset"
        @click.prevent
      >
        {{ phoneNumberText }}
      </Button>
      <Button
        variant="blue"
        class="w-100"
        @click.prevent="handleWhatsappButton"
      >
        WhatsApp
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import Avatar from '~/components/common/Avatar.vue'

  import { useAuthStore, useCommonStore } from '~/store'
  import { truncateText } from '~/utils'
  import { IPropertyCompany } from '~/interfaces'

  interface IUserContactProps {
    isInline?: boolean
    data: IPropertyCompany
  }

  const props = defineProps<IUserContactProps>()

  const authStore = useAuthStore()
  const commonStore = useCommonStore()

  const { state: authState } = storeToRefs(authStore)

  const isAuthenticated = computed(() => !!authState.value.profile.id)

  const phoneNumberText = computed(() => {
    if (isAuthenticated.value) {
      return props.data.user?.phone_number
    }

    return truncateText(props.data.user?.phone_number || '', 11)
  })

  const handleWhatsappButton = () => {
    if (isAuthenticated.value && window) {
      window.open(`https://wa.me/${props.data.user?.phone_number}`, '_blank')
    } else {
      commonStore.toggleLoginModal()
    }
  }
</script>

<style scoped lang="scss">
  .user-contact-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .user-avatar-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .user-total-property {
      color: $base700;
      margin-top: 6px;
    }

    .contact-btn-container {
      display: flex;
      gap: 12px;
    }
  }

  .user-contact--not-inline {
    display: block;

    .user-avatar-container {
      margin-bottom: 12px;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .user-contact-container {
      display: block;

      .user-avatar-container {
        margin-bottom: 12px;
      }

      .contact-btn-container button {
        width: 50%;
      }
    }
  }
</style>
